// extracted by mini-css-extract-plugin
export const root = "hero-module--root--2jzqC";
export const inView = "hero-module--inView--3i55n";
export const disabled = "hero-module--disabled--10skV";
export const blur = "hero-module--blur--3dmob";
export const player = "hero-module--player--gCtDf";
export const text = "hero-module--text--2xUgF";
export const creditTitle = "hero-module--creditTitle--3pYfq";
export const titles = "hero-module--titles--2o8oi";
export const hasMargin = "hero-module--hasMargin--2PcDo";
export const credits = "hero-module--credits--3Z7fi";
export const isActive = "hero-module--isActive--1tB4G";
export const creditsContainer = "hero-module--creditsContainer--2q_eK";
export const highlighted = "hero-module--highlighted--S43xs";