import { Link } from "gatsby";
import React, { useState, useRef, useContext, useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import { cn } from "../lib/helpers";
import StoreContext from "../context/StoreContext";
import ImageSlider from "./image-slider";
import ReactPlayer from "react-player/lazy";

import * as styles from "./project-item.module.css";

const ProjectItem = ({ size = 'size100', project, displayFeaturedImages }) => {
  const [isActive, setIsActive] = useState(false);
  const [videoIsReady, setVideoIsReady] = useState(false);
  const creditsEl = useRef(null);
  const creditsContainerEl = useRef(null);
  const videoPlayerEl = useRef(null);
  const store = useContext(StoreContext);
  const { client, producers, directors, productionCompany, slug, videoShortUrl, title } = project;
  let creditsElHeight;

  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    return function() {
      videoPlayerEl?.current?.seekTo(0);
    }
  }, []);


  const handleMouseEnter = () => {
    creditsElHeight = creditsEl.current.offsetHeight;
    creditsContainerEl.current.style.height = `${creditsElHeight}px`;
    setIsActive(true);
  }

  const handleMouseLeave = () => {
    creditsContainerEl.current.style.height = 0;
    videoPlayerEl?.current?.seekTo(0);
    setIsActive(false);
  }

  if (displayFeaturedImages) {
    return (
      <div 
        className={cn(styles.root, styles[size], inView ? styles.inView : '', store.filterIsActive && productionCompany.title != 'MrMr' ? styles.disabled : '')} 
        onMouseEnter={() => handleMouseEnter()} 
        onMouseLeave={() => handleMouseLeave()}
        ref={ref}
      >
        <ImageSlider images={project.images} slug={slug.current} />

        <div className={styles.text}>
          <div className={cn(styles.titles, isActive ? styles.hasMargin : '')}>
            <h4>{client.title}</h4>
            <h5><em>{title}</em></h5>
          </div>

          <div className={styles.creditsContainer} ref={creditsContainerEl}>
            <ul className={cn(styles.credits, isActive ? styles.isActive : '')} ref={creditsEl}>
              <li>
                <span className={styles.creditTitle}>Director</span>
                {directors.map((director, index) => {
                  if ((index + 1) < directors.length) {
                    return (
                      <span key={index}>
                        <em>{director.title}</em>,
                      </span>
                    );
                  } else {
                    return (
                      <span key={index}>
                        <em>{director.title}</em>
                      </span>
                    ); 
                  }
                })}
              </li>
              <li className={store.filterIsActive && productionCompany.title == 'MrMr' ? styles.highlighted : ''}><span className={styles.creditTitle}>Production Company</span> <em>{productionCompany.title}</em></li>
              <li>
                <span className={styles.creditTitle}>Producer</span>
                {producers.map((producer, index) => {
                  if ((index + 1) < producers.length) {
                    return (
                      <span key={index}>
                        <em>{producer.title}</em>,
                      </span>
                    );
                  } else {
                    return (
                      <span key={index}>
                        <em>{producer.title}</em>
                      </span>
                    ); 
                  }
                })}
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.blur}></div>
      </div>
    );
  } else {
    return (
      <div 
        className={cn(styles.root, styles[size], inView && videoIsReady ? styles.inView : '', store.filterIsActive && productionCompany.title != 'MrMr' ? styles.disabled : '')} 
        onMouseEnter={() => handleMouseEnter()} 
        onMouseLeave={() => handleMouseLeave()}
        ref={ref}
      >
        <Link 
          to={`/project/${slug.current}`} 
          state={{
            modal: true,
            noScroll: true
          }}
        >
          <ReactPlayer
            ref={videoPlayerEl}
            url={videoShortUrl}
            className={styles.player}
            muted
            playsinline
            loop
            autoPlay
            playing={isActive}
            width="100%"
            height="100%"
            config={{
              file: { 
                attributes: { 
                  preload: "auto"
                } 
              } 
            }}
            onReady={() => {
              setVideoIsReady(true);
            }}
          />

          <div className={styles.text}>
            <div className={cn(styles.titles, isActive ? styles.hasMargin : '')}>
              <h4>{client.title}</h4>
              <h5><em>{title}</em></h5>
            </div>

            <div className={styles.creditsContainer} ref={creditsContainerEl}>
              <ul className={cn(styles.credits, isActive ? styles.isActive : '')} ref={creditsEl}>
                <li>
                  <span className={styles.creditTitle}>Director</span>
                  {directors.map((director, index) => {
                    if ((index + 1) < directors.length) {
                      return (
                        <span key={index}>
                          <em>{director.title}</em>,
                        </span>
                      );
                    } else {
                      return (
                        <span key={index}>
                          <em>{director.title}</em>
                        </span>
                      ); 
                    }
                  })}
                </li>
                <li className={store.filterIsActive && productionCompany.title == 'MrMr' ? styles.highlighted : ''}><span className={styles.creditTitle}>Production Company</span> <em>{productionCompany.title}</em></li>
                <li>
                  <span className={styles.creditTitle}>Producer</span>
                  {producers.map((producer, index) => {
                    if ((index + 1) < producers.length) {
                      return (
                        <span key={index}>
                          <em>{producer.title}</em>,
                        </span>
                      );
                    } else {
                      return (
                        <span key={index}>
                          <em>{producer.title}</em>
                        </span>
                      ); 
                    }
                  })}
                </li>
              </ul>
            </div>
          </div>
        </Link>
        <div className={styles.blur}></div>
      </div>
    ); 
  }
};

export default ProjectItem;
