import React, { useContext } from "react";
import { cn } from "../lib/helpers";
import StoreContext from "../context/StoreContext";
import Clock from 'react-live-clock';
import LightSwitch from "./lightswitch";

import * as styles from "./menu-bar.module.css";

const MenuBar = () => {
  const store = useContext(StoreContext);
  return (
    <div className={cn(styles.root, store.isOpen ? styles.menuIsOpen : '')}>
      <div>
        <div className={styles.filter}>
          <b>MrMr FILMS PRODUCTION</b>
          <LightSwitch isActive={store.filterIsActive} on={store.toggleFilter} off={store.toggleFilter} />
        </div>
        <div className={styles.time}>
          <div>
            <b>LONDON GMT</b>
            <em><Clock format={'HH:mm:ss'} ticking={true} timezone={'Europe/London'} /></em>
          </div>
        </div>
        <div>
          <button className={cn(styles.menuButton, store.isOpen ? styles.isActive : '')} type="button" onClick={store.toggleIsOpen}>
            <div>
              <span>About</span>
              <span>Close</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
