import { Link } from "gatsby";
import React, { useState, useRef, useContext } from "react";
import { useInView } from 'react-intersection-observer';
import { cn } from "../lib/helpers";
import StoreContext from "../context/StoreContext";
import ImageSlider from "./image-slider";
import ReactPlayer from "react-player";

import * as styles from "./hero.module.css";

const Hero = ({ project, displayFeaturedImages }) => {
  const { videoShortUrl, client, producers, productionCompany, directors, slug, title, images } = project;
  const [isActive, setIsActive] = useState(false);
  const [videoIsReady, setVideoIsReady] = useState(false);
  const creditsEl = useRef(null);
  const creditsContainerEl = useRef(null);
  const videoPlayerEl = useRef(null);
  const store = useContext(StoreContext);
  let creditsElHeight;

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  const handleMouseEnter = () => {
    creditsElHeight = creditsEl.current.offsetHeight;
    creditsContainerEl.current.style.height = `${creditsElHeight}px`;
    setIsActive(true);
  }

  const handleMouseLeave = () => {
    creditsContainerEl.current.style.height = 0;
    setIsActive(false);
  }

  if (displayFeaturedImages) {
    return (
      <div 
        className={cn(styles.root, inView ? styles.inView : '', store.filterIsActive && productionCompany.title != 'MrMr' ? styles.disabled : '')} 
        onMouseEnter={() => handleMouseEnter()} 
        onMouseLeave={() => handleMouseLeave()}
        ref={ref}
      >
        {images && <ImageSlider images={images} slug={slug.current} />}

        <div className={styles.text}>
          <div className={cn(styles.titles, isActive ? styles.hasMargin : '')}>
            <h4>{client.title}</h4>
            <h5><em>{title}</em></h5>
          </div>

          <div className={styles.creditsContainer} ref={creditsContainerEl}>
            <ul className={cn(styles.credits, isActive ? styles.isActive : '')} ref={creditsEl}>
              <li>
                <span className={styles.creditTitle}>Director</span>
                {directors.map((director, index) => {
                  if ((index + 1) < directors.length) {
                    return (
                      <span key={index}>
                        <em>{director.title}</em>,
                      </span>
                    );
                  } else {
                    return (
                      <span key={index}>
                        <em>{director.title}</em>
                      </span>
                    ); 
                  }
                })}
              </li>
              <li className={store.filterIsActive && productionCompany.title == 'MrMr' ? styles.highlighted : ''}><span className={styles.creditTitle}>Production Company</span> <em>{productionCompany.title}</em></li>
              <li>
                <span className={styles.creditTitle}>Producer</span>
                {producers.map((producer, index) => {
                  if ((index + 1) < producers.length) {
                    return (
                      <span key={index}>
                        <em>{producer.title}</em>,
                      </span>
                    );
                  } else {
                    return (
                      <span key={index}>
                        <em>{producer.title}</em>
                      </span>
                    ); 
                  }
                })}
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.blur}></div>
      </div>
    );
  } else {
    return (
      <div ref={ref} className={cn(styles.root, videoIsReady ? styles.inView : '', store.filterIsActive && productionCompany.title != 'MrMr' ? styles.disabled : '')} onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
        <Link 
          to={`/project/${slug.current}`}
          state={{
            modal: true
          }}
        >
          <ReactPlayer
            ref={videoPlayerEl}
            url={videoShortUrl}
            className={styles.player}
            muted
            playsinline
            loop
            autoPlay
            playing={true}
            width="100%"
            height="100%"
            onReady={() => {
              setVideoIsReady(true);
            }}
          />

          <div className={styles.text}>
            <div className={cn(styles.titles, isActive ? styles.hasMargin : '')}>
              <h4>{client.title}</h4>
              <h5><em>{title}</em></h5>
            </div>

            <div className={styles.creditsContainer} ref={creditsContainerEl}>
              <ul className={cn(styles.credits, isActive ? styles.isActive : '')} ref={creditsEl}>
                <li>
                  <span className={styles.creditTitle}>Director</span>
                  {directors.map((director, index) => {
                    if ((index + 1) < directors.length) {
                      return (
                        <span key={index}>
                          <em>{director.title}</em>,
                        </span>
                      );
                    } else {
                      return (
                        <span key={index}>
                          <em>{director.title}</em>
                        </span>
                      ); 
                    }
                  })}
                </li>
                <li><span className={styles.creditTitle}>Production Company</span> <em>{productionCompany.title}</em></li>
                <li>
                  <span className={styles.creditTitle}>Producer</span>
                  {producers.map((producer, index) => {
                    if ((index + 1) < producers.length) {
                      return (
                        <span key={index}>
                          <em>{producer.title}</em>,
                        </span>
                      );
                    } else {
                      return (
                        <span key={index}>
                          <em>{producer.title}</em>
                        </span>
                      ); 
                    }
                  })}
                </li>
              </ul>
            </div>
          </div>
        </Link>
        <div className={styles.blur}></div>
      </div>
    );
  }
};

export default Hero;
