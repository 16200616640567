import React from "react";
import { cn } from "../lib/helpers";
import ProjectItem from "./project-item";

import * as styles from "./grid-row.module.css";

const GridRow = ({ projects, isFullscreen  }) => (
  <div className={cn(styles.root, isFullscreen ? styles.isFullscreen : '')}>
    {projects && projects.map((project, index) => <ProjectItem key={`${project._key}-${index}`} {...project} />)}
  </div>
);

export default GridRow;
