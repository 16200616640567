import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Figure } from "./figure";

import * as styles from "./image-slider.module.css";

const ImageSlider = ({ images, slug }) => {
  let sliderEl = null;
  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2500,
    draggable: false,
    pauseOnFocus: true,
    pauseOnHover: false
  };

  const play = () => {
    sliderEl?.slickPlay(); 
  }

  const pause = () => {
    sliderEl?.slickPause();
  }

  return (
    <div className={styles.root} onMouseEnter={play} onMouseLeave={pause}>
      <Slider ref={slider => (sliderEl = slider)} {...settings} className={styles.slider}>
        {images.map(image => <Figure key={image._key} node={image} slug={slug} />)}
      </Slider>
    </div>
  )
};

export default ImageSlider;